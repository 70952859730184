.footer {
  width: 100%;
  z-index: 999;
  margin-top: 50px;
}

.footer__main {
  background: $lighter-black;
}

.footer__main div {
  margin: 0 auto;
  line-height: 8px;
  padding: 50px 10px;
  max-width: 930px;
  position: relative;
}

.footer__main p {
  color: $gray;
  font-family: 'Flanders Art Sans Regular', sans-serif;
  font-size: 0.875em;
}

.footer__main p:first-child {
  color: $white;
  font-family: 'Flanders Art Sans Medium', sans-serif;
}

.footer__official, {
  background: $light-black;
}

.footer__official__expandable,
.popup {
  margin: 0 auto;
  padding: 10px;
  max-width: 930px;
  position: relative;
}

.footer__official__expandable span {
  color: $white;
  text-decoration: none;
  font-family: 'Flanders Art Sans Light', sans-serif;
  font-size: 0.875em;
  padding-right: 20px;
}

.footer__official__expandable .line-before {
  font-family: 'Flanders Art Sans Medium', sans-serif;
  color: $gray;
  background-image: url('../img/arrow-up.png');
  background-repeat: no-repeat;
  background-position: right;
}

.footer__official .line-before:hover {
  color: $white;
  cursor: pointer;
}

.footer__official .line-before::before {
  display: block;
  content: ' ';
  position: absolute;
  left: 325px;
  top: 10px;
  bottom: 10px;
  width: 1px;
  background-color: $lighter-black;
}

.branding-logo {
  margin-top: 20px;
  position: absolute;
  left: 0;
  top: 0;
  height: 48px;
  width: 48px;
  background-color: $white;
  background-repeat: no-repeat;
  background-position: 11px center;
  background-image: url('../img/branding-logo.png');

  &::after {
    background-image: url('../img/triangle.png');
    display: block;
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 16px;
    background-repeat: no-repeat;
    background-position: top left;
  }
}

.popup__hide {
  color: $gray;
  font-family: 'Flanders Art Sans Medium', sans-serif;
  font-size: 0.875em;
  float: right;
  padding-right: 20px;
  background-image: url('../img/arrow-down.png');
  background-repeat: no-repeat;
  background-position: right;

  &:hover {
    color: $white;
    cursor: pointer;
  }
}

.popup__info {
  padding-left: 20px;
  margin-left: 25px;
  margin-top: 0;
  font-size: 0.875em;
  color: $gray;
  font-family: 'Flanders Art Sans Light', sans-serif;
  font-weight: 200;
  line-height: 18px;
}

.popup__info h2 {
  margin-top: 10px;
  margin-bottom: -5px;
  color: $white;
  padding: 0;
  font-size: 20px;
  font-family: 'Flanders Art Sans Light', sans-serif;
  font-weight: 200;
}

.popup__info p a {
  text-decoration: underline;
  color: $gray;
}

.popup__info__what {
  color: $white;
  line-height: 18px;
  margin-left: -55px;
  padding-bottom: 10px;
  border-bottom: 1px solid $lighter-black;
  padding-left: 5px;
}

.popup__disclaimer {
  background: $light-black;
}

.popup__disclaimer div {
  margin: 0 auto;
  padding: 10px 10px 10px 15px;
  max-width: 58.125rem;
  position: relative;
  margin-left: -20px;
}

.popup__disclaimer a {
  color: $gray;
  text-decoration: none;
  font-family: 'Flanders Art Sans Medium', sans-serif;
  font-size: 0.875em;
  padding-right: 20px;
}

.popup__disclaimer a:hover {
  color: $white;
}

.popup__disclaimer .line-before::before {
  display: block;
  content: ' ';
  position: absolute;
  left: 90px;
  top: 10px;
  bottom: 10px;
  width: 1px;
  background-color: $lighter-black;
}










