//
// _helpers.scss
// --------------------------------------------------

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.nowrap {
  white-space: nowrap;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.all-caps {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.padding-bottom {
  padding-bottom: 10px;
}
