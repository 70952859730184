.info {
  width: 15px;
  display: inline-block;
  text-decoration: none;
  vertical-align: top;
  margin-top: 3px;
  opacity: 0.5;
  transition: 0.3s all;

  &:hover {
    opacity: 1;
  }

  img {
    width: 100%;
  }
}

legend .info {
  margin-left: 5px;
  margin-top: -5px;
}
