/*------------------------------------*\
    $SETTINGS
\*------------------------------------*/
@import 'utils/variables';
@import '../bower_components/inuit-defaults/settings.defaults';
@import '../bower_components/inuit-responsive-settings/settings.responsive';

/*------------------------------------*\
    $TOOLS
\*------------------------------------*/
@import '../bower_components/inuit-functions/tools.functions';
@import '../bower_components/inuit-mixins/tools.mixins';
@import '../bower_components/inuit-tools-widths/tools.widths';
@import '../bower_components/inuit-responsive-tools/tools.responsive';
@import 'utils/mixins';
@import 'utils/functions';

/*------------------------------------*\
    GENERIC
\*------------------------------------*/
@import '../bower_components/inuit-normalize/generic.normalize';
@import '../bower_components/inuit-box-sizing/generic.box-sizing';

/*------------------------------------*\
    BASE
\*------------------------------------*/
@import '../bower_components/inuit-page/base.page';
@import '../bower_components/inuit-images/base.images';
@import 'base/base';

/*------------------------------------*\
    LAYOUT
\*------------------------------------*/
@import 'layout/default';
@import 'layout/fonts';

/*------------------------------------*\
    COMPONENTS
\*------------------------------------*/
@import 'components/header';
@import 'components/footer';
@import 'components/button';
@import 'components/info';
@import 'components/link';

/*------------------------------------*\
    TRUMPS / HELPERS
\*------------------------------------*/
@import '../bower_components/inuit-clearfix/trumps.clearfix';
@import '../bower_components/inuit-widths/trumps.widths';
@import '../bower_components/inuit-widths-responsive/trumps.widths-responsive';
@import 'utils/helpers';
