//
// _base.scss
// --------------------------------------------------

body {
  font-family: 'Flanders Art Sans Light', sans-serif;
}

fieldset {
  margin-bottom: 30px;
}

legend {
  font-weight: bold;
  padding: 0 5px;
}

input {
  margin-top: 15px;
  margin-right: 5px;
}
