//
// _variables.scss
// --------------------------------------------------

$light-black: #333;
$lighter-black: #494949;
$white: #fff;
$gray: #b8b8b8;

$light-cyan: #25b7bc;
$cyan: #79babd;
$dark-cyan: #57a8ac;
