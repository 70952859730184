.link {
  font-family: 'Flanders Art Sans Medium', sans-serif;
  text-decoration: none;
}

.link--gray {
  color: $lighter-black;
}

.link--light-cyan {
  color: $light-cyan;
}
