.header {
  width: 100%;
  z-index: 999;
}

.header__official {
  background: $light-black;
}

.header__official div {
  margin: 0 auto;
  padding: 10px;
  max-width: 930px;
  position: relative;
}

.header__official a {
  color: $gray;
  text-decoration: none;
  font-family: 'Flanders Art Sans Medium', sans-serif;
  font-size: 0.875em;
  padding-right: 20px;
}

.header__official a:hover {
  color: $white;
}

.header__official .line-before {
  font-family: 'Flanders Art Sans Regular', sans-serif;
}

.header__official .line-before::before {
  display: block;
  content: ' ';
  position: absolute;
  left: 120px;
  top: 10px;
  bottom: 10px;
  width: 1px;
  background-color: $lighter-black;
}

.header__branding div {
  max-width: 58.125rem;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 10px;
}

.header__branding img {
  height: 75px;
}




















