.button,
%button {
  padding: 10px;
  margin-top: 10px;
  font-family: 'Flanders Art Sans Bold', sans-serif;
  border: 0;
  border-radius: 0.1875rem;
  text-decoration: none;
}

.button--small {
  @extend %button;
  padding: 4px 12px;
  font-size: 12px;
  text-transform: uppercase;
}

.button--cyan {
  background-color: $cyan;
  color: $white;

  &:hover {
    background-color: $dark-cyan;
    transition: background-color 0.2s ease-in;
  }
}

.back {
  margin-top: 30px;
  display: inline-block;
}
